import React from 'react';
import Link from 'next/link';
import { Box, Button, Stack, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { BiArrowBack } from 'react-icons/bi';
import { AiOutlineUser } from 'react-icons/ai';
import { BiMailSend } from 'react-icons/bi';

import { CenteredForm } from '@/features/auth/templates/centered-form';
import { forgotPassword } from '@/features/auth/api';

export function ForgotPassword() {
  const [isLoading, setIsLoading] = React.useState(false);
  const form = useForm({
    initialValues: { username: '' },
    validate: {
      username: value => (value.length > 0 ? null : 'Usuário é obrigatório'),
    },
  });

  async function handleSubmit(data: typeof form.values) {
    setIsLoading(true);

    const [error, response] = await forgotPassword(data);

    setIsLoading(false);

    if (error) {
      return showNotification({
        message: error.message,
        color: 'red',
      });
    }

    showNotification({
      message: response.message,
      color: 'green',
      autoClose: 5000,
    });

    form.reset();
  }

  return (
    <CenteredForm
      title="Nova Senha"
      description="Informe seu usuário e um e-mail com a nova senha será enviado"
    >
      <Box component="form" my="lg" onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput
            label="Usuário"
            placeholder="Usuário de acesso"
            icon={<AiOutlineUser />}
            {...form.getInputProps('username')}
          />

          <Button type="submit" leftIcon={<BiMailSend size={24} />} uppercase loading={isLoading}>
            Gerar nova senha
          </Button>
        </Stack>
      </Box>

      <Link href="/login" passHref>
        <Button component="a" variant="subtle" size="sm" leftIcon={<BiArrowBack />}>
          Voltar
        </Button>
      </Link>
    </CenteredForm>
  );
}
