import { showNotification, NotificationProps } from '@mantine/notifications';
import { IoWarning } from 'react-icons/io5';
import { FiCheck } from 'react-icons/fi';

export function showSuccessNotification(props: NotificationProps) {
  showNotification({
    color: 'green',
    py: 20,
    icon: <FiCheck size={24} />,
    sx: theme => ({
      backgroundColor: theme.colors.green,
      color: theme.white,
      '.mantine-Text-root': {
        color: theme.white,
        fontWeight: 700,
      },
      'mantine-Notification-icon': {
        width: 36,
        height: 36,
      },
      '.mantine-ActionIcon-root': {
        color: theme.white,

        '&:hover': {
          backgroundColor: theme.colors.green[7],
        },
      },
    }),
    ...props,
  });
}

export function showErrorNotification(props: NotificationProps) {
  showNotification({
    color: 'red',
    py: 20,
    icon: <IoWarning size={24} />,
    sx: theme => ({
      backgroundColor: theme.colors.red,
      color: theme.white,
      '.mantine-Text-root': {
        color: theme.white,
        fontWeight: 700,
      },
      'mantine-Notification-icon': {
        width: 36,
        height: 36,
      },
      '.mantine-ActionIcon-root': {
        color: theme.white,

        '&:hover': {
          backgroundColor: theme.colors.red[7],
        },
      },
    }),
    ...props,
  });
}
