import { api } from '@/services/api';
import type { ApiCall } from '@/types/api-call';

export type SaveUserProfilePayload = {
  userId: number;
  profileId: number;
};

export const saveUserProfile: ApiCall<null> = async ({
  userId,
  profileId,
}: SaveUserProfilePayload) => {
  try {
    await api.post(`/api/v1/usuario/${userId}/perfil/${profileId}`, {
      usuario_id: userId,
      perfil_id: profileId,
    });

    return [null, null];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message: `Houve um erro inesperado ao tentar vincular o perfil #${profileId} ao o usuário. Tente novamente mais tarde.`,
      },
      null,
    ];
  }
};
