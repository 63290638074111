import React from 'react';
import { useRouter } from 'next/router';

import { AppLayout } from './app-layout';

export type LayoutsProps = {
  children: React.ReactNode;
};

export function Layouts({ children }: LayoutsProps) {
  const router = useRouter();

  if (shouldRenderWithAppLayout(router.pathname)) {
    return <AppLayout>{children}</AppLayout>;
  }

  return <>{children}</>;
}

function shouldRenderWithAppLayout(pathname: string) {
  return [
    '/dashboard',
    '/controleMenus',
    '/controlePerfis',
    '/controlePerfis',
    '/controlePerfis',
    '/controleUsuarios',
    '/controleUsuarios',
    '/controleUsuarios',
    '/coletoresSistema',
    '/coletaPorTema',
    '/modeloDocumento',
    '/profile',
    '/diarioOficial',
    '/processos',
    'consultar_logs_processamento',
    'analise_processos',
    'coleta_simplificada',
    'delimitadores',
  ].some(path => new RegExp(path).test(pathname));
}
