import { MantineProviderProps } from '@mantine/core';

export const mantineDefaultProps: MantineProviderProps['defaultProps'] = {
  Button: { size: 'md' },
  Input: { size: 'md' },
  TextInput: { size: 'md' },
  Select: { size: 'md' },
  DatePicker: { size: 'md' },
  Textarea: { size: 'md' },
  MultiSelect: { size: 'md' },
  PasswordInput: { size: 'md' },
};
