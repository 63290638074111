import { api } from '@/services/api';
import type { ApiCall } from '@/types/api-call';

export const generateNewPassword: ApiCall<{
  retorno: { senha: string };
  mensagem: string;
}> = async (login: string) => {
  try {
    const { data } = await api.post(`/api/v1/usuario/${login}/gerar-senha`);

    return [null, data];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message: 'Houve um erro inesperado ao tentar editar o usuário. Tente novamente mais tarde.',
      },
      null,
    ];
  }
};
