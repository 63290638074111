import { setCookie, parseCookies, destroyCookie } from 'nookies';

import { STORAGE_PREFIX, ONE_DAY_IN_MILLISECONDS } from '@/utils/constants';

const ONE_YEAR_IN_MILLISECONDS = ONE_DAY_IN_MILLISECONDS * 365;
export const STORAGE_THEME_KEY = `${STORAGE_PREFIX}_theme__`;

export const themeStorage = {
  getTheme: (): string | null => {
    const { [STORAGE_THEME_KEY]: theme } = parseCookies();

    return theme;
  },
  setTheme: (theme: string) => {
    setCookie(null, STORAGE_THEME_KEY, theme, {
      maxAge: ONE_YEAR_IN_MILLISECONDS,
    });
  },
  clearTheme: () => {
    destroyCookie(null, STORAGE_THEME_KEY);
  },
};
