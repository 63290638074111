import React from 'react';

export type AppLayoutContextType = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
};

export type AppLayoutProviderProps = {
  children: React.ReactNode;
};

const AppLayoutContext = React.createContext(null as AppLayoutContextType);

export function AppLayoutProvider({ children }: AppLayoutProviderProps) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = React.useCallback(() => setIsMenuOpen(old => !old), []);

  return (
    <AppLayoutContext.Provider value={{ isMenuOpen, toggleMenu }}>
      {children}
    </AppLayoutContext.Provider>
  );
}

export function useAppLayout() {
  const context = React.useContext(AppLayoutContext);

  if (context === null) {
    throw new Error('useAppLayout must be used within a AppLayoutProvider');
  }

  return context;
}
