import { Header, Group, MediaQuery, Burger } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { useAppLayout } from '@/layouts/app-layout/app-layour-provider';

import { Logo } from './logo';
import { ThemeSwitch } from './theme-switch';
import { UserDropdown } from './user-dropdown';

export function AppHeader() {
  const matches = useMediaQuery('(max-width: 600px)');
  const { toggleMenu, isMenuOpen } = useAppLayout();

  return (
    <Header height={80} px={matches ? 'xs' : 'xl'}>
      <Group sx={{ height: '100%' }} position="apart">
        <Group spacing="lg" pl={matches ? 'xs' : 'xl'}>
          <Logo />
        </Group>

        <Group>
          <ThemeSwitch />
          <UserDropdown />

          <MediaQuery largerThan="md" styles={{ display: 'none' }}>
            <Burger onClick={toggleMenu} opened={isMenuOpen} size="md" />
          </MediaQuery>
        </Group>
      </Group>
    </Header>
  );
}
