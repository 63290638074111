import { api } from '@/services/api';
import type { ApiCall } from '@/types/api-call';
import type { User } from '@/features/user-control/types';

export type GetUsersRequestData = {
  id: string;
  nome: string;
};

const REQUEST_DATA: GetUsersRequestData = {
  id: '',
  nome: '',
};

export const getUsers: ApiCall<User[]> = async (userInfo = REQUEST_DATA) => {
  try {
    const { data } = await api.get('/api/v1/usuario', { params: userInfo });

    return [null, data.retorno];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message:
          'Houve um erro inesperado ao tentar filtrar os usuários. Tente novamente mais tarde.',
      },
      null,
    ];
  }
};
