import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { Base64 } from 'js-base64';

import { STORAGE_PREFIX, ONE_DAY_IN_MILLISECONDS } from '@/utils/constants';
import type { User } from '@/features/auth';

export const USER_TOKEN_KEY = `${STORAGE_PREFIX}_user__`;

export const userStorage = {
  getUser: (): User | null => {
    const { [USER_TOKEN_KEY]: userEncoded } = parseCookies();

    if (userEncoded) return JSON.parse(Base64.decode(userEncoded));

    return null;
  },
  setUser: (user: User) => {
    const userEncoded = Base64.encode(JSON.stringify(user));

    setCookie(null, USER_TOKEN_KEY, userEncoded, {
      maxAge: ONE_DAY_IN_MILLISECONDS,
    });
  },
  clearUser: () => {
    destroyCookie(null, USER_TOKEN_KEY);
  },
};
