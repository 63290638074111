import Router from 'next/router';
import Axios, { AxiosRequestConfig } from 'axios';

import { storage } from '@/utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.token.getToken();

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
}

export function getAPIClient() {
  // const URL_LOCAL = 'http://localhost/';
  const URL_LOCAL = 'https://api.staging.jusintelligence.cibernix.com/';

  const axios = Axios.create({
    baseURL: process.env.NEXT_PUBLIC_REACT_APP_BACKENDURL || URL_LOCAL,
  });

  axios.interceptors.request.use(authRequestInterceptor);
  axios.interceptors.response.use(
    response => {
      if (response.data?.status != null && response.data.status !== 0) {
        return Promise.reject(response.data);
      }

      return response;
    },
    error => {
      console.error(error.response.data);

      if (error.response.status === 401) {
        Router.push('/sessao-expirada');
      }

      return Promise.reject(error.response.data);
    }
  );

  return axios;
}
