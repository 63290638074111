import { api } from '@/services/api';
import type { UserAccess } from '@/features/auth/types';
import type { ApiCall } from '@/types/api-call';

export const getUserAccesses: ApiCall<UserAccess[]> = async () => {
  try {
    const { data } = await api.get('/api/v1/acessos_usuario');

    return [null, data.retorno];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message: 'Houve um erro inesperado, ao tentar obter os acessos do usuários.',
      },
      null,
    ];
  }
};
