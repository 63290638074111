import { CSSObject } from '@emotion/react';
import { MantineTheme } from '@mantine/core';

export function getDataTableStyles(theme: MantineTheme): CSSObject {
  const isDark = theme.colorScheme === 'dark';

  return {
    'body .rdt_Table': {
      backgroundColor: 'transparent',
      overflow: 'hidden',
    },

    'body .rdt_TableHeadRow': {
      textTransform: 'uppercase',
      fontWeight: 700,
      backgroundColor: 'transparent',
      borderBottomColor: isDark ? theme.colors.dark[4] : null,
    },

    'body .rdt_TableHead': {
      color: isDark ? theme.white : theme.colors.dark[8],
    },

    'body .rdt_TableRow': {
      color: isDark ? theme.white : theme.colors.dark[8],
      backgroundColor: isDark ? 'transparent' : null,

      '&:nth-of-type(odd)': {
        backgroundColor: isDark ? theme.colors.dark[5] : null,
      },
    },

    'body .rdt_TableRow:not(:last-of-type)': {
      borderBottomColor: isDark ? theme.colors.dark[4] : null,
    },

    'body .rdt_Pagination': {
      color: isDark ? theme.white : theme.colors.dark[8],
      backgroundColor: 'transparent',
      borderTopColor: isDark ? theme.colors.dark[4] : null,

      '& button[id^="pagination"]': {
        color: isDark ? theme.white : theme.colors.dark[8],
        fill: isDark ? theme.white : theme.colors.dark[8],
      },

      '& button[id^="pagination"]:disabled': {
        color: isDark ? theme.colors.dark[2] : theme.colors.dark[1],
        fill: isDark ? theme.colors.dark[2] : theme.colors.dark[1],
      },
    },

    '.collection-progress .rdt_Table': {
      border: `1px solid ${theme.colors.dark[isDark ? 3 : 1]}`,
    },

    '.collection-progress .rdt_TableRow:not(:last-of-type)': {
      borderBottomColor: theme.colors.dark[isDark ? 3 : 1],
    },

    '.collection-progress .rdt_TableHeadRow': {
      borderBottomColor: theme.colors.dark[isDark ? 3 : 1],

      '& .rdt_TableCol:not(:first-of-type)': {
        borderLeft: `1px solid ${theme.colors.dark[isDark ? 3 : 1]}`,
      },
    },

    '.collection-progress .rdt_TableCell': {
      padding: 0,

      '& .mantine-Stack-root > .mantine-Text-root': {
        borderLeft: `1px solid ${theme.colors.dark[isDark ? 3 : 1]}`,

        '&:not(:first-of-type)': {
          borderTop: `1px solid ${theme.colors.dark[isDark ? 3 : 1]}`,
        },
      },
    },

    '.collection-progress .mantine-Text-root': {
      padding: 4,
      whiteSpace: 'nowrap',
    },
  };
}
