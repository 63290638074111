import React, { useEffect } from 'react';
import Router from 'next/router';
import { useModals } from '@mantine/modals';

import type { User, UserAccess, UserProfile } from '@/features/auth/types';
import { login as apiLogin, getUserAccesses, LoginRequestData } from '@/features/auth/api';
import { showErrorNotification } from '@/utils/helpers/notifications';
import { storage } from '@/utils/storage';
import { FullPageLoader } from '@/components/full-page-loader';
import { api } from '@/services/api';
import { Button, Stack, Text } from '@mantine/core';
import { getUserById } from '@/features/user-control/api';

export type AuthContextType = {
  user: User | null;
  userAccesses: UserAccess[] | null;
  login: (data: LoginRequestData) => Promise<User | null>;
  logout: () => void;
  isLoggedIn: boolean;
  userProfile: UserProfile;
  setUserProfile: (userProfile) => void;
};

export const AuthContext = React.createContext(null as AuthContextType);

function isPulicRoute(pathname: string) {
  return (
    pathname.includes('/login') || pathname.includes('/forgot-password') || /^\/$/.test(pathname)
  );
}

export function AuthProvider({ children }) {
  const [user, setUser] = React.useState<User>(null);
  const [userAccesses, setUserAccesses] = React.useState<UserAccess[]>(null);
  const [userProfile, setUserProfile] = React.useState<any>([]);
  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const modals = useModals();

  useEffect(() => {
    if (!user) return;

    async function getUser() {
      const resp = await getUserById(user.id);
      setUserProfile(resp[1]);
    }

    getUser();
  }, [user]);

  const isLoggedIn = !!user;

  const login = React.useCallback(async (loginInfo: LoginRequestData) => {
    const [loginError, loginData] = await apiLogin(loginInfo);

    if (loginError) {
      showErrorNotification({ message: loginError.message });
      return;
    }

    storage.token.setToken(loginData.token);
    storage.refreshToken.setRefreshToken(loginData.refreshToken);
    storage.user.setUser(loginData.user);

    setUser(loginData.user);

    const [userAccessesError, userAccesses] = await getUserAccesses();

    if (userAccessesError) {
      showErrorNotification({ message: userAccessesError.message });
      return;
    }

    setUserAccesses(userAccesses);

    return loginData.user;
  }, []);

  const logout = React.useCallback(() => {
    setUser(null);

    api.defaults.headers['Authorization'] = '';

    storage.token.clearToken();
    storage.refreshToken.clearRefreshToken();
    storage.user.clearUser();

    Router.push('/login');
  }, []);

  function showErrorModal() {
    const id = modals.openModal({
      centered: true,
      withCloseButton: false,
      onClose: () => Router.push('/login'),
      children: (
        <Stack spacing="xl">
          <Text size="md">
            Houve um erro ao tentar carregar o menu de acesso do usuário. Tente novamente mais
            tarde.
          </Text>

          <Button
            color="red"
            onClick={() => {
              Router.push('/login');
              modals.closeModal(id);
            }}
          >
            Fechar
          </Button>
        </Stack>
      ),
    });
  }

  React.useEffect(() => {
    const user = storage.user.getUser();

    if (user) {
      getUserAccesses().then(response => {
        setIsFirstRender(false);

        const [error, userAccesses] = response;

        if (error) return showErrorModal();

        setUser(user);
        setUserAccesses(userAccesses);
      });
    }

    if (!user) setIsFirstRender(false);

    if (!user && isPulicRoute(Router.pathname)) {
      Router.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user && isFirstRender) return <FullPageLoader />;

  return (
    <AuthContext.Provider
      value={{
        user,
        userAccesses,
        login,
        logout,
        isLoggedIn,
        userProfile,
        setUserProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
