import { useRouter } from 'next/router';
import { Anchor, Collapse, UnstyledButton, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FiChevronRight, FiChevronDown, FiGrid } from 'react-icons/fi';

import { UserAccess } from '@/features/auth';
import Link from 'next/link';

export type MenuProps = {
  items: UserAccess[];
};

type MenuItemProps = {
  item: UserAccess;
};

export function Menu({ items }: MenuProps) {
  const { classes } = useStyles();

  if (!items.length) return null;

  return (
    <ul className={classes.menu}>
      {items.map(item => (
        <MenuItem key={item.id} item={item} />
      ))}
    </ul>
  );
}

function MenuItem({ item }: MenuItemProps) {
  const [open, handlers] = useDisclosure(false);
  const router = useRouter();
  const { classes, cx } = useStyles();

  const hasSubItems = !!item?.items?.length;
  const isCurrentPage = router.pathname === item.link;

  if (!hasSubItems) {
    return (
      <li>
        <Link href={item.link} passHref>
          <Anchor
            className={cx(classes.link, classes.item, {
              [classes.active]: isCurrentPage,
            })}
          >
            {item.descricao}
          </Anchor>
        </Link>
      </li>
    );
  }

  return (
    <li style={{ marginBottom: 16 }}>
      <UnstyledButton
        onClick={handlers.toggle}
        style={{ width: '100%' }}
        className={cx(classes.button, classes.item, {
          [classes.active]: open,
        })}
      >
        <FiGrid size={20} />

        <span>{item.descricao}</span>

        {open ? <FiChevronDown size={24} /> : <FiChevronRight size={24} />}
      </UnstyledButton>

      <Collapse in={open}>
        <Menu key={item.id} items={item.items} />
      </Collapse>
    </li>
  );
}

const useStyles = createStyles(theme => ({
  menu: {
    padding: 0,

    '&, ul': {
      listStyle: 'none',
    },
  },

  button: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingRight: theme.spacing.md,

    '& > svg:first-of-type': {
      width: 54,
    },

    span: {
      marginRight: 'auto',
    },
  },

  link: {
    display: 'inline-block',
    width: '100%',
    paddingLeft: 54,
    paddingRight: theme.spacing.xs,

    '&:hover': {
      textDecoration: 'none',
    },
  },

  item: {
    fontWeight: 'bold',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    paddingBlock: theme.spacing.xs,
    borderLeft: '4px solid transparent',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1],
    },
  },

  active: {
    color: theme.colors.blue[6],
    borderLeftColor: theme.colors.blue[6],
  },
}));
