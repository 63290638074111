import { api } from '@/services/api';
import type { ApiCall } from '@/types/api-call';
import type { User } from '@/features/user-control/types';

export type EditUserPayload = User;

export const editUser: ApiCall<{ id: number }> = async (payload: EditUserPayload) => {
  try {
    const { data } = await api.put('/api/v1/usuario', { ...payload });

    return [null, data.retorno[0]];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message: 'Houve um erro inesperado ao tentar editar o usuário. Tente novamente mais tarde.',
      },
      null,
    ];
  }
};
