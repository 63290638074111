import React from 'react';

import { AuthContext } from '@/features/auth/providers/auth-provider';
import type { User } from '@/features/auth/types';

export function useUser(): User | null {
  const context = React.useContext(AuthContext);

  if (context === null) {
    throw new Error('useUser must be used within a AuthProvider');
  }

  return context.user;
}
