import { useMantineColorScheme, ActionIcon, Tooltip } from '@mantine/core';
import { BsFillSunFill, BsFillMoonFill } from 'react-icons/bs';

export function ThemeSwitch() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <Tooltip withArrow transition="fade" transitionDuration={200} label="Mudar tema">
      <ActionIcon variant="default" radius="md" onClick={() => toggleColorScheme()} size={35}>
        {colorScheme === 'dark' ? <BsFillSunFill size={20} /> : <BsFillMoonFill size={20} />}
      </ActionIcon>
    </Tooltip>
  );
}
