import { api } from '@/services/api';
import type { ApiCall } from '@/types/api-call';

export type EditUserStatusPayload = {
  userLogin: string;
  statusName: string;
};

export const editUserStatus: ApiCall<{ mensagem: string }> = async ({
  userLogin,
  statusName,
}: EditUserStatusPayload) => {
  try {
    const { data } = await api.patch(`/api/v1/usuario/${userLogin}/${statusName}`);

    return [null, data];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message: 'Houve um erro inesperado ao tentar editar o usuário. Tente novamente mais tarde.',
      },
      null,
    ];
  }
};
