import React from 'react';
import { AppShell, Stack, useMantineTheme } from '@mantine/core';

import { AppLayoutProvider } from './app-layour-provider';
import { AppHeader } from './components/header';
import { AppNavbar } from './components/navbar';

export type AppLayoutProps = {
  children: React.ReactNode;
};

export function AppLayout({ children }: AppLayoutProps) {
  const theme = useMantineTheme();

  return (
    <AppLayoutProvider>
      <AppShell
        fixed
        navbar={<AppNavbar />}
        header={<AppHeader />}
        navbarOffsetBreakpoint="md"
        styles={{
          main: {
            background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          },
        }}
      >
        <Stack spacing="lg" pt="lg" style={{ position: 'relative', minHeight: '100%' }}>
          {children}
        </Stack>
      </AppShell>
    </AppLayoutProvider>
  );
}
