import { api } from '@/services/api';
import type { ApiCall } from '@/types/api-call';
import type { UserDetail } from '@/features/user-control/types';

export const getUserById: ApiCall<UserDetail> = async (id: number) => {
  try {
    const { data } = await api.get(`/api/v1/usuario/${id}`);

    return [null, data.retorno[0]];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message:
          'Houve um erro inesperado ao tentar acessar os dados do usuário. Tente novamente mais tarde.',
      },
      null,
    ];
  }
};
