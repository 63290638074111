import Head from 'next/head';
import Link from 'next/link';
import { Text, Stack } from '@mantine/core';

import { LoginForm } from '@/features/auth/components/login-form';

import { CenteredForm } from '@/features/auth/templates/centered-form';

export function Login() {
  return (
    <>
      <Head>
        <title>JusIntelligence | Login</title>
      </Head>

      <CenteredForm
        title="Login"
        description="Coloque o seu usuário e senha para entrar no sistema"
      >
        <Stack spacing="xs">
          <LoginForm />

          <Link href="/forgot-password" passHref>
            <Text component="a" variant="link" mt="lg">
              Esqueceu a senha ?
            </Text>
          </Link>
        </Stack>
      </CenteredForm>
    </>
  );
}
