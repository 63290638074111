import { api } from '@/services/api';
import type { User } from '@/features/auth/types';
import type { ApiCall } from '@/types/api-call';

export type LoginRequestData = {
  username: string;
  password: string;
};

export type LoginResponse = {
  token: string;
  refreshToken: string;
  user: User;
};

export const login: ApiCall<LoginResponse> = async (userInfo: LoginRequestData) => {
  try {
    const { data } = await api.post('/login', {
      usuario: userInfo.username,
      senha: userInfo.password,
    });

    return [
      null,
      {
        refreshToken: data.refreshToken,
        token: data.token,
        user: data.retorno[0],
      },
    ];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message: 'Houve um erro inesperado ao tentar realizar o login. Tente novamente mais tarde.',
      },
      null,
    ];
  }
};
