import { setCookie, parseCookies, destroyCookie } from 'nookies';

import { STORAGE_PREFIX, ONE_DAY_IN_MILLISECONDS } from '@/utils/constants';

export const STORAGE_REFRESH_TOKEN_KEY = `${STORAGE_PREFIX}_refresh-token__`;

export const refreshTokenStorage = {
  getRefreshToken: (): string | null => {
    const { [STORAGE_REFRESH_TOKEN_KEY]: refresToken } = parseCookies();

    return refresToken;
  },
  setRefreshToken: (refresToken: string) => {
    setCookie(null, STORAGE_REFRESH_TOKEN_KEY, refresToken, {
      maxAge: ONE_DAY_IN_MILLISECONDS,
    });
  },
  clearRefreshToken: () => {
    destroyCookie(null, STORAGE_REFRESH_TOKEN_KEY);
  },
};
