import { api } from '@/services/api';
import type { ApiCall } from '@/types/api-call';
import type { Profile } from '@/features/user-control/types';

export const getProfilesCombobox: ApiCall<Profile[]> = async () => {
  try {
    const { data } = await api.get('/api/v1/combo/perfil');

    return [null, data.retorno];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message:
          'Houve um erro inesperado ao tentar realizar a listagem de perfis. Tente novamente mais tarde.',
      },
      null,
    ];
  }
};
