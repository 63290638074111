import { Global } from '@mantine/core';

import { getDataTableStyles } from './react-data-table';
import { getReactChronoStyles } from './react-chrono';

export function GlobalStyles() {
  return (
    <Global
      styles={theme => ({
        body: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.colors.black,
        },
        ...getDataTableStyles(theme),
        ...getReactChronoStyles(theme),
      })}
    />
  );
}
