import { refreshTokenStorage } from './refresh-token';
import { tokenStorage } from './token';
import { userStorage } from './user';
import { themeStorage } from './theme';

export const storage = {
  token: tokenStorage,
  refreshToken: refreshTokenStorage,
  user: userStorage,
  theme: themeStorage,
};
