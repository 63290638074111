import React from 'react';
import Router from 'next/router';
import { Box, Button, PasswordInput, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { AiOutlineUser } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';
import { IoEnterOutline } from 'react-icons/io5';

import { useAuth } from '@/features/auth/hooks/use-auth';

export function LoginForm() {
  const { login } = useAuth();
  const [isLoading, setIsLoading] = React.useState(false);
  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },
    validate: {
      username: value => (value.length > 0 ? null : 'Usuário é obrigatório'),
      password: value => (value.length > 0 ? null : 'Senha é obrigatório'),
    },
  });

  const handleSubmit = async (data: typeof form.values) => {
    setIsLoading(true);

    const user = await login(data);

    setIsLoading(false);

    if (user) Router.push('/dashboard');
  };

  return (
    <Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          label="Usuário"
          placeholder="Informe seu usuário de acesso"
          icon={<AiOutlineUser />}
          {...form.getInputProps('username')}
        />

        <PasswordInput
          label="Senha"
          placeholder="Informe sua senha de acesso"
          icon={<RiLockPasswordLine />}
          {...form.getInputProps('password')}
        />

        <Button
          type="submit"
          fullWidth={false}
          uppercase
          rightIcon={<IoEnterOutline size={24} />}
          loading={isLoading}
        >
          Entrar
        </Button>
      </Stack>
    </Box>
  );
}
