import Link from 'next/link';
import { Box, Anchor } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { FiBox } from 'react-icons/fi';

export function Logo() {
  const matches = useMediaQuery('(max-width: 500px)');

  return (
    <Box>
      <Link href="/dashboard" passHref>
        <Anchor
          weight="bold"
          variant="text"
          color="blue"
          transform="uppercase"
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing.xs,

            '> span': {
              display: matches ? 'none' : 'inline-block',
            },

            '> svg': {
              width: matches ? '40px' : '30px',
              height: matches ? '40px' : '30px',
            },
          })}
        >
          <FiBox />
          <span>Jusintelligence</span>
        </Anchor>
      </Link>
    </Box>
  );
}
