import React from 'react';
import { Box, Center, Container, Paper, Stack, Text } from '@mantine/core';
import { FiBox } from 'react-icons/fi';

export type CenteredFormProps = {
  children: React.ReactNode;
  title: string;
  description: string;
};

export function CenteredForm(props: CenteredFormProps) {
  const { children, description, title } = props;

  return (
    <Center style={{ width: '100vw', minHeight: '100vh' }}>
      <Container size="xs">
        <Stack>
          <Text
            weight="bold"
            size="sm"
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: theme.spacing.xs,
            })}
          >
            <FiBox size={46} />
            <span>JUSINTELLIGENCE</span>
          </Text>

          <Paper shadow="xs" p="lg" py="xl" withBorder>
            <Stack>
              <Text transform="uppercase" size="sm" weight="bold">
                {title}
              </Text>

              <Text weight="bold" size="xl">
                {description}
              </Text>

              <Box>{children}</Box>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </Center>
  );
}
