import { CSSObject } from '@emotion/react';
import { MantineTheme } from '@mantine/core';

export function getReactChronoStyles(theme: MantineTheme): CSSObject {
  return {
    'body .horizontal': {
      userSelect: 'initial',
    },

    '.horizontal .timeline-controls': {
      gap: 8,
      background: 'none',
      margin: 0,

      'li:last-of-type': {
        display: 'none',
      },

      button: {
        width: 25,
        height: 25,
        margin: 0,
      },
    },
    '.timeline-horizontal-container .timeline-horz-item-container': {
      height: 80,

      '.timeline-item-title.active': {
        background: 'none',
        color: 'inherit',
      },

      '.timeline-circle:not(.using-icon).active::after': {
        backgroundColor: theme.colors.indigo[1],
      },
    },

    '#react-chrono-timeline': {
      width: '100%',

      '.timeline-card-content': {
        background: 'none',

        '.card-description': {
          padding: 0,
        },
      },

      '.timeline-horz-card-wrapper': {
        minWidth: '100%',
      },
    },
  };
}
