import { setCookie, parseCookies, destroyCookie } from 'nookies';

import { STORAGE_PREFIX, ONE_DAY_IN_MILLISECONDS } from '@/utils/constants';

export const STORAGE_TOKEN_KEY = `${STORAGE_PREFIX}_token__`;

export const tokenStorage = {
  getToken: (): string | null => {
    const { [STORAGE_TOKEN_KEY]: token } = parseCookies();

    return token;
  },
  setToken: (token: string) => {
    setCookie(null, STORAGE_TOKEN_KEY, token, {
      maxAge: ONE_DAY_IN_MILLISECONDS,
    });
  },
  clearToken: () => {
    destroyCookie(null, STORAGE_TOKEN_KEY);
  },
};
