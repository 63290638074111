import React from 'react';
import { Navbar, ScrollArea, Box } from '@mantine/core';

import { useAuth } from '@/features/auth';
import { useAppLayout } from '@/layouts/app-layout/app-layour-provider';

import { Menu } from './menu';

export function AppNavbar() {
  const { isMenuOpen } = useAppLayout();
  const { userAccesses } = useAuth();

  return (
    <Navbar py="md" hiddenBreakpoint="md" hidden={!isMenuOpen} width={{ sm: '60vw', md: 250 }}>
      <Navbar.Section grow component={ScrollArea}>
        <Box pt="xl">
          <Menu items={userAccesses ?? []} />
        </Box>
      </Navbar.Section>
    </Navbar>
  );
}
