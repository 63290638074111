import { api } from '@/services/api';
import type { ApiCall } from '@/types/api-call';

export type ForgotPasswordRequestData = {
  username: string;
};

export const forgotPassword: ApiCall<{ message: string }> = async (
  userInfo: ForgotPasswordRequestData
) => {
  try {
    const { data } = await api.post('/forget_password', {
      usuario: userInfo.username,
    });

    return [null, { message: data.mensagem }];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message: 'Houve um erro ao tentar resetar a senha do usuário.',
      },
      null,
    ];
  }
};
