import { api } from '@/services/api';
import { ApiCall } from '@/types/api-call';

export const deleteUser: ApiCall<null> = async (id: number) => {
  try {
    await api.delete(`/api/v1/usuario/${id}`);

    return [null, null];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message:
          'Houve um erro inesperado ao tentar deletar o usuário. Tente novamente mais tarde.',
      },
      null,
    ];
  }
};
