import { api } from '@/services/api';
import type { ApiCall } from '@/types/api-call';

export type CreateUserPayload = {
  nome: string;
  email: string;
  login: string;
};

export const createUser: ApiCall<{ id: number }> = async (payload: CreateUserPayload) => {
  try {
    const { data } = await api.post('/api/v1/usuario', { ...payload });

    return [null, data.retorno[0]];
  } catch (error) {
    if (error.status) return [{ message: error.mensagem }, null];

    return [
      {
        message:
          'Houve um erro inesperado ao tentar cadastrar o usuário. Tente novamente mais tarde.',
      },
      null,
    ];
  }
};
