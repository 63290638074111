import React from 'react';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import { MantineProvider, ColorSchemeProvider, ColorScheme } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { Layouts } from '@/layouts';
import { AuthProvider } from '@/features/auth';
import { mantineDefaultProps } from '@/styles/mantine/default-props';
import { storage } from '@/utils/storage';
import { GlobalStyles } from '@/styles/mantine/global';
import '@/css/tailwind.css';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

export default function App({ Component, pageProps }) {
  const [colorScheme, setColorScheme] = React.useState<ColorScheme>('dark');

  const toggleColorScheme = React.useCallback(
    (value?: ColorScheme) => {
      const newValue = value || colorScheme === 'dark' ? 'light' : 'dark';

      storage.theme.setTheme(newValue);
      setColorScheme(newValue);
    },
    [colorScheme]
  );

  React.useEffect(() => {
    setColorScheme((storage.theme.getTheme() as ColorScheme) || 'dark');
  }, []);

  return (
    <>
      <Head>
        <title>Jusintelligence - Sistema de inteligência jurídica</title>

        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Head>

      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          defaultProps={mantineDefaultProps}
          theme={{
            colorScheme: colorScheme,
            primaryColor: 'blue',
          }}
        >
          <GlobalStyles />

          <ModalsProvider>
            <NotificationsProvider position="top-right" autoClose={3000}>
              <AuthProvider>
                <Layouts>
                  <Component {...pageProps} />
                </Layouts>
              </AuthProvider>
            </NotificationsProvider>
          </ModalsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </>
  );
}
