import { useRouter } from 'next/router';
import { UnstyledButton, Menu, Avatar } from '@mantine/core';
import { BiUser } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';

import { useAuth } from '@/features/auth';

export function UserDropdown() {
  const router = useRouter();
  const { logout, userProfile, user } = useAuth();

  const profileImg = userProfile.foto ? `data:image/*;base64,${userProfile.foto}` : '';

  return (
    <Menu
      p={0}
      control={
        <UnstyledButton
          sx={theme => ({
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.dark[4],
          })}
        >
          <Avatar src={profileImg} size={45} radius={45} mx="auto" />
        </UnstyledButton>
      }
    >
      <Menu.Label
        px="md"
        transform="uppercase"
        weight="bold"
        sx={theme => ({
          fontWeight: 'bold',
          fontSize: theme.fontSizes.sm,
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        })}
      >
        {user?.nome}
      </Menu.Label>

      <Menu.Item icon={<BiUser size={16} />} onClick={() => router.push('/profile')}>
        Perfil do Usuário
      </Menu.Item>

      <Menu.Item icon={<FiLogOut size={16} />} onClick={logout}>
        Sair
      </Menu.Item>
    </Menu>
  );
}
